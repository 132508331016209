@font-face {
    font-family: "Ginto Nord";
    src: url("#{$static-path}/fonts/GintoNord-Ultra.woff") format("woff");
    font-weight: $font-weight-heavy;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Roobert";
    src: url("#{$static-path}/fonts/Roobert-Regular.woff") format("woff");
    font-weight: $font-weight-normal;
    font-style: normal;
}

@font-face {
    font-family: "Roobert";
    src: url("#{$static-path}/fonts/Roobert-Medium.woff") format("woff");
    font-weight: $font-weight-medium;
    font-style: normal;
}

@font-face {
    font-family: "Roobert";
    src: url("#{$static-path}/fonts/Roobert-SemiBold.woff") format("woff");
    font-weight: $font-weight-bold;
    font-style: normal;
}
