$static-path: "/static/nlix" !default;

// Typography
$font-weight-medium: 600;
$font-weight-heavy: 900;

$navbar-height-desktop: 120px;
$navbar-height-mobile: 72px;

// Hero
$hero-media-min-height-lg: 664px;

// Banner
$green-border: 1px solid $green;
