.more-info {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: $green-900;
    margin-top: auto;

    &:hover {
        color: $green;
        svg {
            border: 1px solid $green;
        }
    }

    &__icon {
        border-radius: 50%;
        border: 1px solid $green-900;
        height: 32px;
        width: 32px;
        padding: 8px;
    }

    &__label {
        margin-left: 8px;
        font-weight: 600;
    }
}
