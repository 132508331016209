.menu {
    color: $green;
    font-size: 24px;

    &__close-btn {
        margin-left: auto;
        padding-top: 16px;
        padding-bottom: 16px;
        color: $green;
        &:hover {
            color: white;
        }

        img {
            width: 48px;
            height: 48px;
        }
    }

    &__back-btn {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
        padding: 0;
        color: $green;
        &:hover {
            color: $white;
        }
    }

    img {
        color: $green;

        &:hover {
            fill: $green-900;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        z-index: 10;
        background-color: $black;
        padding: 36px;
        overflow-y: auto;
        border-bottom: 1px solid rgba($white, 0.3);
        opacity: 1;

        @include media-breakpoint-up(lg) {
            top: $navbar-height-desktop;
            max-height: 50vh;
            height: auto;
        }
    }

    &__list-item {
        a {
            color: $green;
            &:hover {
                color: white;
            }
        }
    }

    &__myNlIx-bn {
        display: flex;
        justify-content: center;
        font-size: 16px !important;
        &:hover {
            background-color: $green;
            color: $black;
        }
    }

    &__list-item-container {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 16px;

        @include media-breakpoint-up(lg) {
            grid-template-columns: repeat(3, max-content);
            column-gap: 3rem;
        }
    }

    &__title {
        margin-left: -10px;
    }

    &__submenu-toggle-btn {
        -webkit-transition: none !important;
        -moz-transition: none !important;
        -o-transition: none !important;
        transition: none !important;
        font-size: 24px;
        color: $green;
        padding: 0;

        &:hover {
            box-shadow: none;
            color: white;
        }
    }

    &__toggle-btn {
        margin-right: 8px;
    }
}

.hide {
    display: none;
}

.show {
    display: flex;
}
