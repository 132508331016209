//-- Folder structure: https://sass-guidelin.es/#architecture
//
//-- Abstracts (e.g. variables, mixins, placeholders, functions)
@import "abstracts/bootstrap";
@import "abstracts/variables";
@import "abstracts/mixins";

//-- Vendors
@import "vendors/bootstrap";

//-- Base
@import "base/fonts";
@import "base/typography";

//-- Components
@import "components/buttons";
@import "components/bulletpoint-list";
@import "components/connected-networks";
@import "components/customer-logos";
@import "components/hero-text";
@import "components/hero-media";
@import "components/footer";
@import "components/header";
@import "components/image";
@import "components/image-overview";
@import "components/contact";
@import "components/section";
@import "components/map_hero";
@import "components/link-overview";
@import "components/location_detail";
@import "components/banner";
@import "components/title";
@import "components/table";
@import "components/quote";
@import "components/datacenter-list";
@import "components/european-stats";
@import "components/routeserver-stats";
@import "components/local-stats";
@import "components/menu";
@import "components/service_teaser";
@import "components/services_overview";
@import "components/more_info";
@import "components/teaser_card";
@import "components/teaser";
@import "components/form";
@import "components/error-page";
@import "components/image-gallery";
@import "components/foldout-list";

.flex-wrapper {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: space-between;
}

.flex-body {
    padding-bottom: 48px;

    @include media-breakpoint-up(md) {
        padding-bottom: 64px;
    }

    @include media-breakpoint-up(lg) {
        padding-bottom: 80px;
    }
}

body {
    font-synthesis: none;
    overflow-x: hidden;
    list-style: none;
}
