@import "~mapbox-gl/dist/mapbox-gl";

$map-height-mobile: 1100px;
$map-height-tablet: 1300px;
$map-height-desktop: 1400px;

.map-aspect-ratio {
    margin-top: -$navbar-height-desktop;
    position: relative;
    height: $map-height-mobile;

    > * {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        height: $map-height-tablet;
    }

    @include media-breakpoint-up(lg) {
        height: $map-height-desktop;
    }
}

.map-aspect-ratio--standalone {
    height: $map-height-mobile;

    @include media-breakpoint-up(md) {
        height: $map-height-tablet;
    }

    @include media-breakpoint-up(lg) {
        height: $map-height-desktop;
    }

    .map-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        //padding: 23px;

        //@include media-breakpoint-up(md) {
        //    padding: 33px;
        //}

        @include media-breakpoint-up(lg) {
            justify-content: center;
        }

        //&__title {
        //    font-size: 34px;
        //
        //    @include media-breakpoint-up(md) {
        //        font-size: 64px;
        //    }
        //
        //
        //
        //    @include media-breakpoint-up(xxl) {
        //        font-size: 80px;
        //    }
        //}
    }
}

.map-container {
    position: relative;
    height: 100%;
}

.map-preview {
    height: 100%;
    background-color: #071220;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 calc(100% - 80px));
}

.map-content-container {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding-bottom: 80px;
    display: flex;
    flex-direction: column;
}

.map-content-container-gradient-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(180deg, rgba(0, 20, 0, 0) 60%, rgba(0, 20, 0, 1) 100%);
}

.map-content {
    margin-top: auto;
    padding-top: 160px;
    width: 100%;
    //padding-left: 20px;
    //padding-right: 20px;

    @include media-breakpoint-up(lg) {
        //padding-left: 100px;
        //padding-right: 100px;
        width: 60%;
    }

    @include media-breakpoint-up(xxl) {
        width: 50%;
    }

    &__title {
        color: $green;
        text-transform: uppercase;
        font-size: 34px;
        line-height: 1;
        font-weight: 900;
        word-break: break-word;

        @include media-breakpoint-up(xs) {
            word-break: normal;
        }

        @include media-breakpoint-up(sm) {
            font-size: 48px;
        }

        @include media-breakpoint-up(md) {
            font-size: 52px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 64px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 72px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 78px;
        }
    }

    &__subtitle {
        color: #f2f7f5;
        font-size: 20px;
        line-height: 1.4;
        font-weight: 500;
        margin-left: 4px;

        @include media-breakpoint-up(sm) {
            font-size: 22px;
            margin-left: 6px;
        }

        @include media-breakpoint-up(md) {
            font-size: 26px;
        }

        @include media-breakpoint-up(lg) {
            font-size: 32px;
            margin-left: 10px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 36px;
        }

        @include media-breakpoint-up(xxl) {
            font-size: 38px;
        }
    }
}

.mapboxgl-marker {
    z-index: 1;
}

.map {
    height: 100%;

    &:focus {
        outline: none;
    }

    & .location-marker {
        z-index: 1;

        &:hover {
            cursor: pointer;
            z-index: 2;
        }
    }

    & .location-container {
        position: relative;
        background-color: transparent;
        border: 0;

        &:focus {
            outline: none;
        }

        & .location {
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-property: color, background-color, border-color, text-decoration-color, fill,
                stroke, opacity, box-shadow, transform, filter, backdrop-filter,
                -webkit-text-decoration-color, -webkit-box-shadow, -webkit-transform, -webkit-filter,
                -webkit-backdrop-filter;
            transition-duration: 250ms;
            background-color: rgba(0, 224, 137, 0.25);
            height: 40px;
            width: 40px;
            border-radius: 9999px;

            & .dot-container {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                justify-content: center;
                align-items: center;

                & .dot {
                    position: relative;
                    height: 24px;
                    width: 24px;
                    background-color: $green;
                    border-radius: 9999px;
                    overflow: hidden;

                    > svg {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        transform: rotate(-90deg);
                        color: #ffd400;
                    }
                }
            }

            & .icon {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: none;
                justify-content: center;
                align-items: center;

                > svg {
                    height: 14px;
                    width: 14px;
                    color: #001400;
                }
            }

            &:hover {
                background-color: rgba(0, 224, 137);
                box-shadow: 0 0 16px 0 $green;

                & .dot-container {
                    & .dot {
                        display: none;
                    }
                }

                & .icon {
                    display: flex;
                }
            }
        }

        & .location--small {
            height: 24px;
            width: 24px;

            & .dot-container {
                & .dot {
                    height: 12px;
                    width: 12px;
                }
            }

            &:hover {
                transform: scale(1.7);

                & .icon {
                    transform: scale(0.6);
                }
            }
        }
    }
}

.map-tooltip {
    z-index: 5;
    transition-property: box-shadow;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
    pointer-events: none;
    font-family: $font-family-base;

    &__shadow-container {
        filter: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06));
    }

    &__arrow {
        margin-top: -1px;
        display: flex;
        justify-content: center;
        align-items: center;

        > svg {
            width: 30px;
            height: 12px;
        }
    }

    &__container {
        padding: 0.5rem 1rem;
        background-color: #fff;
        white-space: nowrap;
    }

    &__status {
        display: flex;
        flex-direction: row;
        align-items: center;

        > svg {
            height: 14px;
            width: 14px;
            margin-right: 6px;
        }

        > span {
            font-size: 12px;
            color: #001400;
        }
    }

    &__title {
        font-weight: 600;
        color: #001400;
        font-size: 16px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.mapboxgl-ctrl-bottom-right {
    padding-bottom: 16px;
}

.animation-marker {
    margin-top: 120px;
    height: 8px;
    position: absolute;
    width: 8px;
    border-radius: 50%;
    background: $green;
    opacity: 0;
}

.animation-ripple {
    margin-top: 120px;
    height: 120px;
    position: absolute;
    width: 120px;
    border-radius: 50%;
    border: 10px solid $green;
    opacity: 0;
}

.animation-latency {
    margin-top: 120px;
    position: absolute;
    opacity: 0;
    color: white;
    font-size: 18px;
    width: 100px;
    height: 30px;
    text-align: center;
    z-index: 10;
    pointer-events: none;
}

.animation-latency-text {
    padding: 2px 6px;
    text-align: center;
    background-color: $black;
    border-radius: 15px;
}
