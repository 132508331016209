@mixin toggle-icon {
    display: flex;
    margin-right: 8px;
    align-content: center;
    flex-wrap: wrap;
    font-size: 42px;
    color: $green;
    font-weight: normal;
    width: 24px;
}


.foldout-list {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
    border: none;
    text-align: left;

    &__item {
        display: flex;
        flex-direction: column;
        border-bottom: solid 1px grey;
        padding-bottom: 8px;
        padding-top: 8px;
    }

    &__toggle {
        display: flex;
        flex: 1;
        justify-content: flex-start;
        border: none;
        background-color: inherit;
        padding: 0;
        font-weight: bold;
        text-align: left;
        margin-bottom: 0;

        &:before {
            @include toggle-icon;
            content: '+';
        }
    }

    &__open {
        &:before {
            @include toggle-icon;
            content: '-';
        }
    }

    &__content {
        height: 0;
        visibility: hidden;
        overflow: hidden;
        cursor: default;
        transition: all 0.5s ease-out;
        margin-left: 32px;
    }

    .folded-out {
        visibility: visible;
    }
}
