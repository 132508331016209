.link-overview {
    &__item {
        border-top: 1px solid $black;
        height: 100%;
        display: flex;
        flex-direction: column;

        .title {
            font-size: 32px;
            line-height: 40px;
            margin-top: 18px;
        }
    }
}
