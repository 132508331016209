.teaser {
    max-width: 100%;

    &__pagination-btn-wrapper {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        align-content: space-between;
        min-width: 100px;
    }

    &__pagination-btn {
        padding: 0;
        border-radius: 50%;
        color: $black;
        border: 1px solid $black;

        &:hover {
            color: $green;
            border: 1px solid $green;
        }

        &:active,
        &:focus {
            color: inherit;
            border: 1px solid $black;
            box-shadow: none;
        }
    }

    &__cards-container {
        overflow: auto;
        display: flex;
        scroll-snap-type: x mandatory;
        position: relative;
    }

    @include media-breakpoint-up(lg) {
        max-width: unset;
        overflow-x: unset;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
}
