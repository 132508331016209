.location-detail {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    &__container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow: hidden;
    }

    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__card-container {
        pointer-events: none;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        display: flex;
        max-width: 100%;

        @include media-breakpoint-up(xl) {
            left: auto;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 2.5rem;
        }
    }

    &__card-inner {
        pointer-events: auto;
        width: 100vw;
        height: 100%;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
            max-width: 38rem;
        }
    }

    &__header {
        display: flex;
        align-items: center;

        &__live-indicator {
            width: 8px;
            height: 8px;
            margin-right: 8px;
            border-radius: 50%;
            background-color: red;
            margin-top: 2px;
        }

        &__live-text {
            font-size: 15px;
            width: 100%;
        }
    }

    &__card {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: scroll;
        background-color: #f3f7f5;
        padding-top: 1rem;
        box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
            rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.1) 0px 8px 10px -6px;
    }

    &__content {
        position: relative;
        padding-top: 50px;
        flex: 1;
        padding-left: 24px;
        padding-right: 24px;

        @include media-breakpoint-up(md) {
            padding-left: 93px;
            padding-right: 93px;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 56px;
            padding-right: 56px;
        }
    }

    &__heading-container {
        padding-left: 24px;

        @include media-breakpoint-up(md) {
            padding-left: 93px;
        }

        @include media-breakpoint-up(xl) {
            padding-left: 56px;
        }

        padding-right: 16px;
    }

    &__heading {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        > div {
            min-width: 0;
        }
    }

    &__title {
        padding-top: 2rem;
        font-weight: 600;
        color: #001400;
        font-size: 34px;
        font-family: $font-family-base;
        line-height: 1.2;
        margin-top: 20px;
        word-break: break-word;

        @include media-breakpoint-up(md) {
            font-size: 36px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 48px;
        }
    }

    &__status {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 8px;

        > svg {
            height: 18px;
            width: 18px;
            margin-right: 8px;
        }

        > span {
            font-size: 16px;
            color: #001400;
        }
    }

    &__close {
        background-color: transparent;
        border: 0;

        &:focus {
            outline: none;
        }

        > span {
            @include visually-hidden;
        }

        > svg {
            height: 32px;
            width: 32px;
            color: #001400;

            @include media-breakpoint-up(md) {
                height: 48px;
                width: 48px;
            }
        }
    }

    &__footer {
        padding: 16px 24px;

        @include media-breakpoint-up(md) {
            padding: 32px;
        }

        @include media-breakpoint-up(xl) {
            padding: 32px 73px;
        }

        background-color: #f2f7f5;
        border-top: 1px solid #dddddd;
        box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.08);
    }

    &__button {
        position: relative;
        display: flex;
        flex-direction: row;
        height: 50px;
        width: 100%;
        border: 0;
        margin: 0;
        padding: 0;
        background-color: transparent;
        text-decoration: none;

        > svg {
            height: 100%;
        }

        &-content {
            flex: 1;
            background-color: #00e089;
            height: 50px;
            display: flex;
            font-weight: 600;
            align-items: center;
            justify-content: center;
            color: #001400;
        }
    }

    &__transition {
        &-enter {
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 500ms;
            // transition-duration: 700ms; on larger viewports
        }

        &-enter-from {
            transform: translate(100%);
        }

        &-enter-to {
            transform: translate(0);
        }

        &-leave {
            transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
            transition-duration: 500ms;
            // transition-duration: 700ms; on larger viewports
        }

        &-leave-from {
            transform: translate(0);
        }

        &-leave-to {
            transform: translate(100%);
        }
    }
}

.latency-record {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &__title {
        font-weight: 600;
        font-size: 16px;

        @include media-breakpoint-up(xl) {
            font-size: 18px;
        }
    }

    &__details {
        display: flex;
        align-items: center;
    }

    &__value {
        font-size: 20px;
        color: #00e089;
        font-weight: 400;
        margin-right: 12px;

        @include media-breakpoint-up(xl) {
            font-size: 24px;
        }
    }

    &__bar-container {
        position: relative;
    }

    &__bar {
        height: 8px;
        width: 64px;
        background-image: linear-gradient(270deg, #ff0000 0%, #ffbb00 51%, #00f2be 100%);
        border-radius: 4px;
    }

    &--warning {
        .latency-record__value,
        .latency-record__title {
            color: #feab00;
        }
    }

    &--error {
        .latency-record__value,
        .latency-record__title {
            color: #ff4d00;
        }
    }
}
