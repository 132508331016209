.hero-text {
    background-color: $black;
    @include angle(after, true, 3deg);

    // Margin for the slanting edge
    margin-bottom: 40px;

    &__content-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        @include bottomGradient;
        padding: 80px 0 16px;

        > .container {
            position: relative;
            z-index: 3;
        }
    }

    &__title {
        color: $green;
        margin-bottom: 0;
    }

    &__subtitle {
        color: $white;
        margin-bottom: 0;
    }

    &__text {
        color: $white;
        margin-top: 16px;
        margin-bottom: 56px;
    }

    &__back-button {
        margin-bottom: 24px;
    }
    @include media-breakpoint-up(sm) {
        &__content {
            padding: 120px 0 32px;
        }
    }

    @include media-breakpoint-up(md) {
        &__content {
            padding: 120px 0 56px;
        }

        &__back-button {
            margin-bottom: 40px;
        }
    }

    @include media-breakpoint-up(lg) {
        min-height: 400px;

        &__content {
            padding: 175px 0 80px;
        }

        &__content-background {
            img {
                height: 100%;
            }
        }

        &__text {
            margin-top: 32px;
        }

        &__back-button {
            margin-bottom: 48px;
        }
    }

    @include media-breakpoint-up(xl) {
        min-height: 600px;
    }
}
