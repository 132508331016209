.image-overview {
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-weight: 600;
        font-family: "Roobert SemiBold", sans-serif;
        color: $green-900;

        &__wrapper {
            display: flex;
            flex-direction: column;
        }

        &__image {
            width: 340px;
            height: 400px;

            clip-path: polygon(
                0 0,
                calc(100% - 24px) 0,
                100% 24px,
                100% 100%,
                20% 100%,
                0 calc(100% - 24px),
                0 0
            );
        }

        &__title {
            font-size: 32px;
            line-height: 36px;
            max-width: 340px;
        }

        &__link {
            text-decoration: none;
        }

        &:hover {
            color: $green;
            cursor: pointer;
        }
    }
}


.image-with-title__item {

    &:hover {
        color: $green-900;
        cursor: inherit;
    }
}
