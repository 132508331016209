.network-details {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 75px;
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    margin-top: 0;

    @include media-breakpoint-up(xl) {
        margin-top: auto;
    }

    &__badge {
        z-index: 3;
        border: 0;
        background-color: transparent;
        margin: 0;
        margin-bottom: 20px;
        margin-right: auto;

        clip-path: polygon(
            0 0,
            calc(100% - 10px) 0,
            100% 10px,
            100% 100%,
            10px 100%,
            0 calc(100% - 10px)
        );

        @include media-breakpoint-up(md) {
            display: none;
        }

        &--desktop {
            display: none;
            margin-left: auto;
            margin-right: 0;

            @include media-breakpoint-up(md) {
                display: inline-flex;
            }
        }

        > svg {
            height: 100%;
            color: #ff65f5;
        }

        &-content {
            flex: 1;
            background-color: #ff65f5;
            height: 100%;
            max-width: 556.5px;
            padding: 8px 16px;
            display: flex;
            align-items: center;
            justify-content: center;

            > svg {
                height: 20px;
                width: 20px;
            }

            span {
                display: none;
                margin-left: 8px;
            }

            @include media-breakpoint-up(xxl) {
                max-width: 646.5px;
            }

            @include media-breakpoint-up(xxxl) {
                max-width: 786.5px;
                span {
                    display: inline-block;
                }
            }
        }

        &-icon-wrapper {
            flex: 1 0 auto;
            padding-right: 8px;
            height: 24px;
            width: 30px;
        }
    }
}

.banner-container {
    display: flex;
    flex-direction: column-reverse;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}

.banner {
    flex: 1;

    &__heading {
        display: flex;
        flex-direction: row;
    }

    &__title {
        font-weight: 600;
        color: $green;
        margin-top: -14px;
        margin-left: 8px;
        margin-right: 8px;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
        font-size: 16px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
        }
    }

    &__line {
        flex: 1;
        height: 1px;
        background-color: $green;
    }

    &__content-container {
        display: flex;
        flex-direction: column;
        position: relative;
        height: 110px;
        width: 100%;
        border-left: $green-border;
        border-right: $green-border;

        @include media-breakpoint-up(md) {
            height: 120px;
        }

        @include media-breakpoint-up(lg) {
            height: 160px;
            border-bottom: $green-border;
        }
    }

    &__content {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: space-between;
        padding-left: 50px;
        padding-right: 25px;
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
            padding-right: 50px;
        }
    }

    &__spotlights {
        display: flex;
        justify-content: center;
        margin-top: -35px;
    }

    &__spotlight-not-selected {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 0px;
        border-radius: 10px;
        background: rgba(0, 224, 137, 1);
        transition: width 0.2s, height 0.2s, margin-left 0.2s, margin-right 0.2s, margin-top 0.2s,
            border-radius 0.2s, background 0.2s;
        cursor: pointer;

        &__dot {
            margin-top: 2px;
            margin-left: 2px;
            margin-right: 2px;
            width: 16px;
            height: 16px;
            border-radius: 8px;
            background: $black;
            transition: width 0.2s, height 0.2s, margin-left 0.2s, margin-right 0.2s,
                margin-top 0.2s, border-radius 0.2s, background 0.2s;
        }
    }

    @media (hover: hover) {
        &__spotlight-not-selected {
            &__dot {
                :hover > & {
                    margin-top: 7px;
                    margin-left: 7px;
                    margin-right: 7px;
                    width: 16px;
                    height: 16px;
                    border-radius: 8px;
                    background: rgba(0, 224, 137, 1);
                }
            }
            &:hover {
                width: 30px;
                height: 30px;
                border-radius: 15px;
                margin-left: 5px;
                margin-right: 5px;
                margin-top: -5px;
                background: rgba(0, 224, 137, 0.4);
            }
        }
    }

    &__spotlight-selected {
        width: 20px;
        height: 20px;
        margin-left: 10px;
        margin-right: 10px;
        border-radius: 10px;
        background: rgba(0, 224, 137, 1);
        transition: width 0.2s, height 0.2s, margin-left 0.2s, margin-right 0.2s, margin-top 0.2s,
            border-radius 0.2s, background 0.2s;

        &__dot {
            margin-top: 10px;
            margin-left: 10px;
            margin-right: 10px;
            width: 0px;
            height: 0px;
            border-radius: 0px;
            background: $black;
            transition: width 0.2s, height 0.2s, margin-left 0.2s, margin-right 0.2s,
                margin-top 0.2s, border-radius 0.2s, background 0.2s;
        }
    }

    &__value {
        flex: 1;
        font-weight: 600;
        font-size: 21px;
        color: #fff;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.2);

        @include media-breakpoint-up(md) {
            font-size: 40px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 48px;
        }
    }

    &__text {
        flex: 1;
        font-weight: 600;
        font-size: 18px;
        color: #fff;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.2);

        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;

        opacity: 0;
        max-width: 0;
        transition: max-width 0.1s, opacity 0.4s;
        transition-delay: 0.4s, 0s;

        margin-bottom: 10px;

        @include media-breakpoint-up(md) {
            font-size: 24px;
        }

        @include media-breakpoint-up(xl) {
            font-size: 32px;
        }

        &__active {
            max-width: 1000px;
            opacity: 1;
            transition: max-width 0.1s, opacity 0.4s;
            transition-delay: 0.5s, 0.6s;
        }
    }

    &__button {
        flex-shrink: 0;
        font-weight: 600;
        height: 40px;
        width: 40px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 2px solid $green;
        color: $green;
        transition: all;
        transition-duration: 200ms;
        margin-left: 40px;

        > svg {
            height: 10px;
        }

        @include media-breakpoint-up(md) {
            height: 48px;
            width: 48px;
            border-radius: 48px;

            > svg {
                height: 14px;
            }
        }

        &:hover {
            background-color: $green;
            box-shadow: 0 0 16px 0 $green;
            color: #001400;
        }
    }

    &__label {
        flex: 1;
        text-align: left;
        color: #f2f7f5;
        text-shadow: 2px 2px rgba(0, 0, 0, 0.2);
        font-size: 16px;

        @include media-breakpoint-up(md) {
            font-size: 18px;
            text-align: center;
            white-space: nowrap;
        }
    }

    &__statistics {
        flex: 1;
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(md) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__statistic {
        display: flex;
        align-items: flex-start;
        flex-direction: row;

        & .banner__value {
            text-align: left;
            color: $green;

            @include media-breakpoint-up(md) {
                text-align: center;
            }
        }

        & .banner__unit {
            font-size: medium;
        }

        @include media-breakpoint-up(md) {
            flex-direction: column;
            justify-content: center;
            margin-right: auto;
        }
    }
}

.banner--left {
    flex: 0.75;

    & .banner__content-container {
        border-bottom: $green-border;
    }

    @include media-breakpoint-up(lg) {
        margin-right: -1px;
    }

    @include media-breakpoint-up(xl) {
        flex: 1;
    }
}

.banner--single {
    & .banner__content-container {
        border-bottom: $green-border;
    }

    & .banner__statistics {
        @include media-breakpoint-up(xxl) {
            flex: 0.8;
        }
    }

    & .banner__statistic {
        flex-direction: row;

        @include media-breakpoint-up(md) {
            flex-direction: column;
            margin-right: auto;
        }

        @include media-breakpoint-up(xl) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    & .banner__label {
        margin-right: 1rem;
    }
}

.banner__corner--desktop {
    display: none;

    @include media-breakpoint-up(lg) {
        display: block;
    }
}

.banner__corner--mobile {
    display: block;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}
