.bulletpoint-list {
    color: $green;
    &__logo-x {
        li {
            list-style-image: url(../static/nlix/svgs/logo-x.svg);
            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }
        li span {
            position: relative;
            left: 14px;
            top: -2px;
        }
    }

    &__checkmark {
        li {
            list-style-image: url(../static/nlix/svgs/checkmark.svg);
            &:not(:last-child) {
                margin-bottom: 14px;
            }
        }
        li span {
            position: relative;
            left: 14px;
            top: -2px;
        }
    }
}
