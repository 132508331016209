.customer-logos {
    &__container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 24px;
    }

    &__logo {
        cursor: pointer;
    }
}