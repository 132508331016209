.service_teaser {
    background-color: #ffffff;
    padding: 12px;
    box-shadow: 0 16px 32px rgba(#ffffff, 4%);

    &:hover {
        background-color: $green;
    }

    &__icon {
        display: inherit;
        padding-left: 10px;
        padding-right: 10px;
        padding-top: 16px;
        padding-bottom: 16px;
        svg {
            width: 72px;
            height: 72px;
        }
    }

    &__title {
        margin-left: 8px;
        margin-bottom: 0;
        margin-top: 8px;
        text-align: center;

        font-weight: 600;
        font-size: 16px;
        line-height: 20px;

        @include media-breakpoint-up(sm) {
            text-align: start;
            margin-top: 0;
        }

        @include media-breakpoint-up(md) {
            font-size: 22px;
            line-height: 30px;
        }
    }

    a {
        text-decoration: none;
        color: inherit;
        display: flex;
        align-content: center;
        align-items: center;
        flex-direction: column;
        flex: 1;

        @include media-breakpoint-up(sm) {
            flex-direction: row;
        }
    }
}

.services__teaser_list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 16px;
    row-gap: 16px;
    flex-wrap: wrap;
    flex: 2;

    @include media-breakpoint-up(xxl) {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }
}

.services_preview {
    display: flex;
    flex-direction: column;
    padding: 32px;
    gap: 32px;
    font-size: 18px;

    @include borderRightTopCutoff();

    &__last {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        font-size: 72px;
    }
}

.service__container {
    .title {
        font-weight: 600;
        font-size: 72px;
        line-height: 80px;
        color: $black;
        margin-bottom: 32px;
    }
}
