tr.hide-table-padding td {
    padding: 0;
}

.expand-button {
    position: relative;
}

.accordion-toggle .expand-button:after {
    position: absolute;
    left: 0.75rem;
    top: 50%;
    transform: translate(0, -50%);
    content: "-";
}

.accordion-toggle.collapsed .expand-button:after {
    content: "+";
}

.htLeft {
    text-align: left;
}
.htCenter {
    text-align: center;
}
.htRight {
    text-align: right;
}
.htJustify {
    text-align: justify;
}
.htTop {
    vertical-align: top;
}
.htMiddle {
    vertical-align: middle;
}
.htBottom {
    vertical-align: bottom;
}

.table-no-top-border {
    border-top: none !important;
    border-color: $grey;

    th {
        font-size: 20px;
        color: $black;
        font-weight: 600;
        letter-spacing: 0.6px;
        line-height: 24px;
        padding: 8px;
    }

    td {
        font-size: 14px;
        color: $black;
        font-weight: 400;
        letter-spacing: 0.56px;
        line-height: 24px;
        padding: 8px;
    }

    caption {
        color: $green-900;
        padding: 14px;
    }

    @include media-breakpoint-up(md) {
        th {
            font-size: 22px;
            color: $black;
            font-weight: 600;
            letter-spacing: 0.6px;
            line-height: 24px;
            padding: 10px;
        }

        td {
            font-size: 16px;
            color: $black;
            font-weight: 400;
            letter-spacing: 0.56px;
            line-height: 24px;
            padding: 10px;
        }
    }

    @include media-breakpoint-up(lg) {
        th {
            font-size: 24px;
            color: $black;
            font-weight: 600;
            letter-spacing: 0.6px;
            line-height: 24px;
            padding: 14px;
        }

        td {
            font-size: 18px;
            color: $black;
            font-weight: 400;
            letter-spacing: 0.56px;
            line-height: 24px;
            padding: 14px;
        }
    }
}
