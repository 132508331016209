.hero-media {
    background-color: $black;
    @include angle(after, true, 3deg);

    // Margin for the slanting edge
    margin-bottom: 40px;

    &__visual {
        @include bottomGradient;
        position: relative;
        height: 580px;
        width: 100%;
        overflow: hidden;

        // Transparency layer on top of image
        &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba($black, 0.2);
            z-index: 2;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        picture {
            height: 100%;

            img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        video {
            display: block;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    &__video-block {
        display: block;
        width: 100%;
        object-fit: cover;
    }

    &__content-background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
            object-fit: cover;
        }
    }

    &__content {
        @include bottomGradient;
        position: relative;
        padding: 120px 0 32px;
        margin-top: -210px;
        z-index: 2;

        > .container {
            position: relative;
            z-index: 3;
        }
    }

    &__title {
        color: $green;
        margin-bottom: 0;
    }

    &__subtitle {
        color: $white;
        margin-bottom: 0;
    }

    &__text {
        color: $white;
        margin-top: 16px;
        margin-bottom: 0;
    }

    &__buttons {
        margin-top: 28px;
    }

    &__back-button {
        height: 26px;
        margin-bottom: 24px;
    }

    @include media-breakpoint-up(md) {
        &__content {
            padding: 150px 0 56px;
            margin-top: -250px;
        }

        &__visual {
            height: 700px;
        }

        &__back-button {
            margin-bottom: 40px;
        }

        &__buttons {
            margin-top: 40px;
        }
    }

    @include media-breakpoint-up(lg) {
        min-height: $hero-media-min-height-lg;

        &__visual {
            position: absolute;
            height: 100%;
        }

        &__image,
        &__video {
            left: 25%;
        }

        &__content {
            position: static;
            display: flex;
            align-items: center;
            margin-top: 0;
        }

        &__content-background {
            img {
                height: 100%;
            }
        }

        &__text {
            margin-top: 32px;
        }

        &__back-button {
            margin-bottom: 48px;
        }
    }
}
