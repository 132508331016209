.local-stats {
    padding: 40px;
    padding-bottom: 30px;
    font-size: 22px;

    &__item {
        @include partialTopBorder();
        @include partialBottomBorderLastChild();
    }

    &__text {
        font-size: 20px;
        font-weight: bold;
    }

    &__number {
        font-size: 20px;
        color: $green-900;
    }

    @include media-breakpoint-up(md) {
        &__text {
            font-size: 28px;
        }

        &__number {
            font-size: 28px;
        }

        &__unit {
            font-size: 28px;
        }
    }

    img {
        display: block;
        margin: auto;
        max-width: 100%;
    }

    &__image-wrapper {
        @include media-breakpoint-down(lg) {
            padding-top: 20px;
        }
    }

    @include borderRightTopCutoff();
}
