.teaser_card {
    font-weight: 600;
    font-family: "Roobert SemiBold", sans-serif;
    flex-direction: column;
    height: auto;
    width: 400px;
    flex-shrink: 0;
    scroll-snap-align: start;
    margin-right: 20px;

    &__image-wrapper {
        height: 450px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 400px;

        @include media-breakpoint-up(lg) {
            width: 100%;
            flex-grow: 1;
            flex-shrink: 0;
        }
    }

    &__image {
        width: 100%;
        height: 400px;

        clip-path: polygon(
            0 0,
            calc(100% - 24px) 0,
            100% 24px,
            100% 100%,
            20% 100%,
            0 calc(100% - 24px),
            0 0
        );
    }

    &__title {
        font-size: 26px;
        line-height: 34px;
    }

    &:hover {
        color: $green;
        cursor: pointer;
    }
}
