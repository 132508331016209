@mixin angle($pseudo, $flip: false, $angle: 1.5deg) {
    // Possible values for $pseudo are: before, after, both
    @if $pseudo == "before" or $pseudo == "after" or $pseudo == "both" {
        position: relative;
        z-index: 1;
        $selector: if($pseudo == "both", "&:before,&:after", "&:#{$pseudo}");

        #{$selector} {
            background: inherit;
            content: "";
            display: block;
            height: 50%;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden; // for Chrome Windows
        }

        @if $pseudo == "before" {
            #{$selector} {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }
        }

        @if $pseudo == "after" {
            #{$selector} {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 100%;
                } @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }

        @if $pseudo == "both" {
            &:before {
                top: 0;

                @if $flip {
                    transform: skewY($angle * -1);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle);
                    transform-origin: 100% 0;
                }
            }

            &:after {
                bottom: 0;

                @if $flip {
                    transform: skewY($angle);
                    transform-origin: 0 0;
                } @else {
                    transform: skewY($angle * -1);
                    transform-origin: 100%;
                }
            }
        }
    }
}

@mixin bottomGradient($color: $black, $height: 60%) {
    &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: $height;
        background: linear-gradient(0deg, $color 0%, rgba($color, 0%) 100%);
    }
}

@mixin borderRightTopCutoff() {
    border: none;
    position: relative;
    clip-path: polygon(0 0, calc(100% - 48px) 0, 100% 48px, 100% 100%, 0 100%);

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $black;
        clip-path: polygon(
            0 100%, //1
            1px 100%, //2
            1px 1px,//3
            calc(100% - 48px) 1px,//4
            calc(100% - 1px) 48px, //5
            calc(100% - 1px) calc(100% - 1px), //6
            1px  calc(100% - 1px), //7
            1px 100%, //8
            100% 100%,
            100% 48px,
            calc(100% - 48px) 0,
            0 0,
        );
    }

}

@mixin partialTopBorder() {
    &::before {
        border-bottom: 1px solid grey;
        content: "";
        width: 96%;
        margin-left: 2%;
    }
}

@mixin partialBottomBorderLastChild() {
    &:last-of-type {
        &::after {
            border-bottom: 1px solid grey;
            content: "";
            width: 96%;
            margin-left: 2%;
        }
    }
}
