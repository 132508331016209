.quote {
    display: flex;
    flex-direction: column;
    padding-top: 0px;
    padding-bottom: 25px;

    &__icon {
        width: 96px;
        margin-left: -25px;
    }

    &__text-container {
        flex: 1;
        margin-left: 0;
        margin-top: 0;
    }

    &__text {
        font-family: "Roobert SemiBold", sans-serif;
    }

    @include media-breakpoint-up(lg) {
        flex-direction: row;

        padding-top: 10px;

        &__text-container {
            margin-left: 24px;
            padding-top: 20px;
        }

        &__icon {
            margin-left: 0;
        }
    }
}
