.footer {
    color: $white;
    height: auto;

    &__row {
        height: 100%;
        padding: 32px 0;

        @include media-breakpoint-up(xl) {
            padding: 0;
        }
    }

    &__social-media-icon {
        border-radius: 50%;
        background-color: $green;
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }

        svg {
            fill: $black;
            width: 27px;
            height: auto;
        }

        &:hover {
            background-color: $white;

            svg {
                fill: $green-900;
            }
        }
    }

    &__nlix-logo {

        svg {
            fill: $green;
            width: 200px;
            height: 48px;

            &:hover {
                fill: $green-900;
            }
        }
    }

    &__list-item {
        background-color: transparent;
        border: none;
        text-align: center;

        a {
            color: $white;
            text-decoration: none;

            &:hover {
                color: $green;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        height: $navbar-height-desktop;
    }
}


.flex-footer {
    background-color: $black;
}
