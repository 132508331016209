// Use this file to override all bootstrap default variables, all variables
// which can be overridden here are listed in this file:
// https://raw.githubusercontent.com/twbs/bootstrap/v5.1.3/scss/_variables.scss

// Color system
$black: #001400; // Base black
$white: #f2f7f5; // Base white
$green: #00e089; // Outlaw green
$green-900: #005f3c; // Outlaw dark green
$yellow: #ffd400; // Cyber yellow
$orange: #ff4d00; // Operator orange
$purple: #826fff; // Terminal purple
$pink: #ff5ef5; // Program pink
$grey: #cedcd5; // grey

$primary: $green;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 3;

// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-family-base: "Roobert", "Helvetica Neue", Arial, sans-serif;
$headings-font-family: "Roobert", "Helvetica Neue", Arial, sans-serif;

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.
$btn-border-radius: 0;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xxl: 1600px,
    xxxl: 1700px,
    xxxxl: 1800px,
);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1320px,
    xxxl: 1600px,
);
