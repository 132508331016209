.services_overview {
    display: grid;
    grid-template-columns: auto;
    column-gap: 64px;
    row-gap: 48px;

    @include media-breakpoint-up(md) {
        grid-template-columns:  repeat(2, 1fr);;
        row-gap: 64px;
    }

    @include media-breakpoint-up(xl) {
        grid-template-columns: repeat(3, 1fr);
    }
}


.service_block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    &__icon {
        flex: 1;
        display: flex;
        background-color: #FFFFFF;
        justify-content: center;
        align-items: center;
        height: 300px;
        padding: 32px;
        clip-path: polygon(
            0 calc(100% - 12px),
            0 0,
            calc(100% - 16px) 0,
            100% 16px,
            100% 100%,
            48px 100%,
        );

        svg {
            width: 140px;
            height: 140px;
        }

        &:hover {
            background-color: $green;
        }

    }

    &__title {
        font-size: 32px;
        font-weight: 600;
        margin-top: 32px;
        color: $black;


        &:hover {
            color: $green;
        }
    }

    a {
        text-decoration: none;
    }
}
