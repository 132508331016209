$form-bottom-border: 2px solid $black;

.form-submission-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
    margin-bottom: 1em;
}

.form-submission-checkmark {
    width: 30%;
    max-width: 200px;
    margin: auto;
    fill: $green;
}

.form-submission-message {
    margin-top: 1.25em;
    margin-left: auto;
    margin-right: auto;
    max-width: 25em;
    text-align: center;
}

.form-text-area {
    display: flex;
    flex-direction: column;
}

textarea {
    border: none;
    overflow: auto;
    outline: none;
    resize: none;
    border-bottom: $form-bottom-border;
    background-color: transparent;
    height: 80px;
    color: $black;

    &::placeholder {
        font-size: 18px;
        font-weight: 400;
        opacity: 40%;
        font-family: Roobert, sans-serif;
        color: inherit;
    }
}

.form-label {
    margin-bottom: 16px;
    font-size: 24px;
    font-weight: 600;
    font-family: Roobert, sans-serif;
    color: $black;
}

.form-help-text {
    font-size: 18px;
    font-weight: 400;
    opacity: 40%;
    font-family: Roobert SemiBold, sans-serif;
}

// Radio button styling
.form-radio {
    display: inline-flex;

    &__label {
        font-size: 18px;
        font-weight: 400;
        font-family: Roobert, sans-serif;
        color: #001400;
        margin-left: 8px;
        position: absolute;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-left: 0;

        input {
            width: 28px;
            height: 28px;
            appearance: none;
            border: 2px solid $black;
            border-radius: 50%;
            background-clip: content-box;
            padding: 3px;

            &:checked {
                background-color: $green;
                padding: 0;
            }
        }
    }
}

// Radio button styling
input[type="radio"] {
    width: 28px;
    height: 28px;
    appearance: none;
    border: 2px solid $black;
    border-radius: 50%;
    background-clip: content-box;
    padding: 3px;

    &:checked {
        background-color: $green;
        padding: 0;
    }
}

// Dropdown styling
select {
    flex: 1;
    display: flex;
    padding-bottom: 8px;
    background-color: transparent;
    appearance: none;
    border: none;
    border-radius: 0;
    border-bottom: $form-bottom-border;
    font-weight: 600;

    &:focus-visible {
        outline: none;
    }
}

input[type="text"]:not(:placeholder-shown) ~ p,
input[type="number"]:not(:placeholder-shown) ~ p,
input[type="email"]:not(:placeholder-shown) ~ p {
    display: flex !important;
}

// Input text styling
input[type="text"],
input[type="number"],
input[type="email"] {
    flex: 1;
    outline: none;
    border: none;
    -webkit-appearance: none;
    padding-bottom: 8px;
    background-color: transparent;
    border-bottom: $form-bottom-border;

    &::placeholder {
        font-size: 18px;
        font-weight: 400;
        opacity: 40%;
        font-family: Roobert, sans-serif;
        color: inherit;
    }
}

.input-text {
    display: flex;
    position: relative;
    flex-direction: column;

    &__label {
        position: absolute;
        right: 2px;
        top: 4px;
        font-weight: 600;
        justify-content: flex-end;
    }
}

// Checkbox styling
input[type="checkbox"] {
    width: 24px;
    height: 24px;
    border: 2px solid $black;
    appearance: none;
    &:checked {
        background-image: url("../static/nlix/svgs/icon-check.svg");
        background-size: cover;
    }
}

.select-dropdown {
    display: flex;
    position: relative;

    &__caret {
        position: absolute;
        right: 2px;
        top: 4px;
        font-weight: 600;
        justify-content: flex-end;
        pointer-events: none;
    }
}

// File input styling
.form-file {
    display: flex;
    flex-direction: column;

    &__file-name {
        font-size: 18px;
        font-weight: 600;
    }
}

.form-file-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px $black dashed;
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: 18px;
    font-family: Roobert, sans-serif;

    &__bold-label {
        font-weight: 600;
    }
}

// File delete button styling
.btn-file-delete {
    color: red;
    border: transparent;
    margin-top: 4px;
    margin-right: -10px;

    &:focus,
    &:focus-visible {
        outline: none;
        box-shadow: none;
    }

    svg {
        fill: currentColor;
        width: 32px;
        height: 32px;
    }
}


.invalid-feedback-textarea {
    display: none;
}


// Error state styling
.was-validated {
    textarea:invalid,
    input[type="text"]:invalid,
    input[type="number"]:invalid,
    input[type="email"]:invalid,
    select:invalid {
        color: red;
        border-bottom: 2px solid red;
    }

    input[type="checkbox"]:invalid,
    input[type="radio"]:invalid {
        border: 2px solid $red;
    }

    input[type="checkbox"]:invalid + .form-check-label,
    input[type="radio"]:invalid + .form-check-label {
        color: red;
    }

    .invalid-feedback {
        font-size: 14px;
        display: block !important;
    }
    .invalid-feedback-textarea {
        font-size: 14px;
        display: block !important;
    }
    .invalid-textarea {
        color: red;
        border-bottom: 2px solid red;
    }
}

// Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.smiley-rating-wrapper {
    label {
        width: 18%;
        max-width: 100px;

        // Hide radio buttons
        [type=radio] {
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
        }

        // style of radio button images
        img {
            width: 100%;
            cursor: pointer;
            border-radius: 100px;
        }

        // Unselected radio button image
        [type=radio]+img {
            cursor: pointer;
            transition: all 0.2s;
        }

        // Unselected radio button image hover
        [type=radio]+img:hover {
            cursor: pointer;
            box-shadow: 0 0 0 3px #04364a;
            background-color: transparent;
            transition: all 0.2s;
        }

        // Selected radio button image
        [type=radio]:checked+img {
            box-shadow: 0 0 0 1px #04364a;
            background-color: #04364a;
            cursor: default;
        }

    }
}