.european_stats {
    padding: 40px;
    padding-bottom: 30px;

    display: flex;
    flex-direction: column;

    &__first-row {
        @include partialTopBorder();
        @include media-breakpoint-up(xl) {
            @include partialBottomBorderLastChild();
        }
    }

    &__second-row {
        @include partialTopBorder();
        @include partialBottomBorderLastChild();
    }

    &__number {
        font-size: 24px;
        color: $green-900;
    }
    &__title {
        font-size: 20px;
        font-weight: bold;
    }

    img {
        flex-grow: 3;
        flex-shrink: 0;
        width: 100%;
        max-width: 600px;
        margin: auto;
        padding-top: 20px;
    }

    @include borderRightTopCutoff();

    @include media-breakpoint-up(md) {
        &__number {
            font-size: 32px;
        }
        &__title {
            font-size: 28px;
        }
    }
}
