h1,
.h1 {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 3.33px;
    text-transform: uppercase;
    font-family: "Ginto Nord";

    @include media-breakpoint-up(md) {
        font-size: 64px;
        line-height: 72px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 80px;
        line-height: 88px;
    }
}

h2,
.h2 {
    font-size: 34px;
    line-height: 42px;
    letter-spacing: 0.45px;

    @include media-breakpoint-up(md) {
        font-size: 56px;
        line-height: 64px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 72px;
        line-height: 80px;
    }
}

h3,
.h3 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.4px;

    @include media-breakpoint-up(md) {
        font-size: 36px;
        line-height: 44px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 48px;
        line-height: 56px;
    }
}

h4,
.h4 {
    font-size: 24px;
    line-height: 32px;
    letter-spacing: 0.67px;

    @include media-breakpoint-up(md) {
        font-size: 32px;
        line-height: 40px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 32px;
        line-height: 40px;
    }
}

h5,
.h5 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 0.6px;

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 32px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 32px;
    }
}

h6,
.h6 {
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.56px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 24px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 18px;
        line-height: 24px;
    }
}

.lead {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.6px;

    @include media-breakpoint-up(md) {
        font-size: 24px;
        line-height: 40px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 40px;
    }
}

.small {
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.5px;
}

p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.56px;

    @include media-breakpoint-up(md) {
        font-size: 18px;
        line-height: 28px;
    }
}

a {
    color: $green-900;
}
