.routeserver_stats {
    padding: 40px;
    padding-bottom: 30px;
    padding-top: 20px;

    display: flex;
    flex-direction: column;

    &__first-row {
        @include partialTopBorder();
        @include media-breakpoint-up(xl) {
            @include partialBottomBorderLastChild();
        }
    }

    &__second-row {
        @include partialTopBorder();
        @include partialBottomBorderLastChild();
    }

    &__number {
        font-size: 24px;
        color: $green-900;
    }
    &__title {
        font-size: 20px;
        font-weight: bold;
    }
    &__header {
        font-size: 20px;
        font-weight: bold;

        padding-top: 12px;
        padding-bottom: 12px;
    }

    @include borderRightTopCutoff();

    @include media-breakpoint-up(md) {
        &__number {
            font-size: 32px;
        }
        &__title {
            font-size: 28px;
        }
        &__header {
            font-size: 28px;
        }
    }
}
