.header {
    z-index: 40;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: $navbar-height-mobile;
    width: calc(100vw);
    display: flex;
    align-items: center;
    font-size: 16px;

    &__container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-content: center;
        align-items: center;
    }

    &__logo_wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    &__nlix-short-logo {
        width: 56px;
        height: 48px;
    }

    &__nlix-logo {
        @include media-breakpoint-down(lg) {
            width: 100px;
        }
    }

    .nav_item {
        margin-left: 24px;

        a {
            color: $green;

            &:hover {
                color: white;
            }
        }

        @include media-breakpoint-up(xxl) {
            margin-left: 48px;
        }

        &__dropdown-btn {
            -webkit-transition: none !important;
            -moz-transition: none !important;
            -o-transition: none !important;
            transition: none !important;
            padding: 0;
            color: $green;

            svg {
                width: 20px;
                height: 20px;
            }

            &:hover {
                box-shadow: none;
                color: white;
                svg g {
                    fill: white;
                }
            }

            &:focus {
                box-shadow: none;
            }

            &[dropdown] {
                color: white;

                svg {
                    transform: rotate(180deg) translateY(-3px);
                    g {
                        fill: white;
                    }
                }
            }
        }
    }

    & a {
        text-decoration: none;
    }

    .btn-secondary {
        flex-shrink: 0;
        padding: 16px;
        min-width: fit-content;
        margin: 0;

        @include media-breakpoint-down(md) {
            padding: 8px 16px;
        }
        @include media-breakpoint-down(sm) {
            margin-right: 8px;
        }
    }

    &__menu {
        svg {
            fill: $green;

            &:hover {
                fill: $green-900;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        font-size: 18px;
    }

    @include media-breakpoint-up(sm) {
        height: $navbar-height-desktop;
    }

    &--menu-open {
        @include media-breakpoint-up(lg) {
            background-color: $black;
        }
    }

    .btn {
        font-size: inherit;
    }
}
