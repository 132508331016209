.image {
    width: 100%;
    picture {
        height: 100%;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;

            clip-path: polygon(0 90%, 0 0, calc(100% - 32px) 0, 100% 32px, 100% 100%, 20% 100%);
        }
    }

    .divider {
        color: #cedcd5;
    }

    &__text {
        color: #001400;
        font-size: 18px;
        font-weight: 400;
        font-family: "Roobert", sans-serif;
        padding-left: 0;
        padding-top: 32px;

        @include media-breakpoint-up(sm) {
            padding-left: 25%;
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
}

.big__image-text {
    padding-top: 32px;
    padding-left: 16px;
    padding-bottom: 32px;

    .divider {
        display: flex;
    }

    @include media-breakpoint-up(sm) {
        padding-left: 20%;
    }
}
