.section {
    padding-top: 48px;

    @include media-breakpoint-up(md) {
        padding-top: 64px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 80px;
    }
}

.section__cols {
    & > *:not(:last-child) {
        margin-bottom: 32px;

        @include media-breakpoint-up(md) {
            margin-bottom: 48px;
        }

        @include media-breakpoint-up(xl) {
            margin-bottom: 0;
            margin-right: 80px;
        }
    }

    @include media-breakpoint-up(xl) {
        display: flex;

        &.section__cols--align-center {
            align-items: center;
        }
    }

    & > .section__2col--ratio-xl-70 {
        @include media-breakpoint-up(xl) {
            width: 70%;
        }
    }
    & > .section__2col--ratio-xl-30 {
        @include media-breakpoint-up(xl) {
            width: 30%;
        }
    }
    & > .section__2col--ratio-xl-50 {
        @include media-breakpoint-up(xl) {
            width: 50%;
        }
    }

    & > .section__3col {
        @include media-breakpoint-up(xl) {
            width: 33.333%;
        }
    }
}

.section__1col,
.section__2col,
.section__3col {
    & > :not(:last-child) {
        margin-bottom: rem(24px);

        @include media-breakpoint-up(md) {
            margin-bottom: rem(32px);
        }
    }
}
