.connected-networks {
    height: 800px;
    overflow-y: scroll;
    &__header {
        color: $green-900;
        position: sticky;
        top: -1px;
        z-index: 10;
        background: $white;
        vertical-align: top !important;
        input {
            border: 1px solid $grey !important;
        }
    }

    &__tabs {
        .nav-link {
            color: $green-900;
        }
    }

    &__button-header-wrapper {
        border-bottom: 1px solid $grey;
        border-left: 1px solid $grey;
        border-right: 1px solid $grey;
        display: flex;
        justify-content: flex-end;

        button {
            margin: 6px;
            min-width: 0px;
        }
    }

    table {
        border-top: hidden;
    }
    tbody {
        border-top: 1px solid currentColor !important;
    }

    &__location-column {
        font-weight: bold;
    }

    .sr-only {
        display: none;
    }

    label {
        display: block;
    }
}
