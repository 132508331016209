.datacenter-list {
    &__datacenter-item {
        border-bottom: solid 1px grey;
    }

    &__datacenter-item-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;

    }

    &__datacenter-toggle {
        -webkit-user-select: none; /* Safari */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* IE10+/Edge */
        user-select: none; /* Standard */
        font-size: 42px;
        width: 1em;
    }

    &__datacenter-name {
        font-size: 20px;
        font-weight: 900;
    }

    &__green {
        color: $green;
    }

    &__open {
        cursor: default;
        padding-bottom: 16px;
    }
}
