.btn-primary,
.btn-secondary {
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    line-height: 24px;
    letter-spacing: 0.5px;
    padding: 8px 24px;
    clip-path: polygon(
        0 0,
        calc(100% - 16px) 0,
        100% 16px,
        100% 100%,
        16px 100%,
        0 calc(100% - 16px)
    );
    border: none;
    min-width: 177px;
    margin: 6px 0px;

    @include media-breakpoint-up(md) {
        font-size: 1rem;
        padding: 12px 24px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
        padding: 16px 32px;
    }

    &:hover {
        box-shadow: none;
    }
}

.btn-secondary {
    position: relative;
    background-color: transparent;
    color: $green;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $green;
        clip-path: polygon(
            0 calc(100% - 16px),
            2px calc(100% - 16px),
            2px 2px,
            calc(100% - 16px - 1px) 2px,
            calc(100% - 2px) 16px,
            calc(100% - 2px) calc(100% - 2px),
            16px calc(100% - 2px),
            2px calc(100% - 16px),
            0 calc(100% - 16px),
            0 100%,
            100% 100%,
            100% 50%,
            100% 0,
            0 0
        );
    }
}

.btn-tertiary {
    font-size: 0.875rem;
    font-weight: $font-weight-medium;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: $green-900;

    @include media-breakpoint-up(md) {
        font-size: 1rem;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.125rem;
    }
}

.btn-back {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    color: $white;
    text-decoration: none;
    padding: 0;

    span {
        font-size: inherit;
        font-weight: $font-weight-medium;
        line-height: 1;
        letter-spacing: 0.75px;
    }

    .icon {
        width: 24px;
        height: 24px;
        margin-right: 16px;
    }

    &:hover {
        color: $green;
    }

    &:focus {
        box-shadow: none;
    }

    @include media-breakpoint-up(md) {
        font-size: 24px;
    }
}

.btn-icon {
    padding: 0;
}
