.image_gallery {
    display: flex;
    flex-wrap: wrap;

    &__item {
        display: inline-flex;
        flex: 1 0 100%;
        position: relative;
        transition: 0.3s;

        img {
            width: 100%;
            height: 400px;
            object-fit: cover;
        }

        @include media-breakpoint-up(xxl) {
            flex: 1 0 33%;
        }

        @include media-breakpoint-between(lg, xxl) {
            flex: 1 0 50%;
        }

        &:hover {
            cursor: pointer;

            img {
                opacity: 0.1;
            }

            .image_gallery__search {
                display: block;
            }
        }
    }

    &__search {
        position: absolute;
        display: none;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
    }

}


.highlighted-image-wrapper {
    display: none;
    position: fixed;
    z-index: 100;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.7);
    flex-direction: row;
    align-content: center;

    &__button {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;

        @include media-breakpoint-down(md) {
            position: absolute;
            top: 50%
        }

    }

    button {
        color: darkgrey;

        svg {
            width: 64px;
            height: 64px;
        }

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:hover {
            color: lightgrey;
        }
    }
}

.highlighted-image__container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding-top: 48px;
    padding-bottom: 48px;
    justify-content: center;
    flex: 1;
}


.highlighted-image__close {
    display: flex;
    justify-content: flex-end;
    align-content: center;
    margin-right: -16px;

    svg {
        width: 48px;
        height: 48px;
    }
}


@keyframes highlight {
    from { transform:scale(0)}
    to { transform:scale(1)}
}


.highlighted-image {
    animation-name: highlight;
    animation-duration: 0.6s;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.btn-next {
    @include media-breakpoint-down(md) {
        position: absolute;
        top: 50%;
        right: 0;
    }
}
