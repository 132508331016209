.error-page {
    &__header {
        height: 72px;
        width: 100%;
        background-color: $black;
        @include media-breakpoint-up(sm) {
            height: 120px;
        }
    }
}
