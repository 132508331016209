.contact {
    background-color: $green;
    width: 100%;

    padding-top: 80px;
    clip-path: polygon(100% 80px, 100% 100%, 15% 100%, 0 97%, 0 0);

    &__wrapper {
        background: linear-gradient(to bottom, $white 0%, $white 50%, $black 50%, $black 100%);
    }

    &__lets-talk {
        text-align: center;
        line-height: 0.9;
        margin-bottom: -0.6em;
        margin-top: 0.4em;
        font-size: 11vw;
    }

    .contact-blocks__wrapper {
        display: flex;
        flex-direction: column;

        @include media-breakpoint-up(xl) {
            flex-direction: row;
        }
    }

    &__row {
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        padding-left: 0;
        width: 100%;

        @include media-breakpoint-up(xl) {
            height: 150px;
        }

        @include media-breakpoint-up(sm) {
            flex-direction: row;
            justify-content: space-around;
            text-align: unset;
        }
    }

    &__contact-action-block {
        cursor: pointer;
        line-height: 1.1;
        font-weight: bold;
        height: 14vw;
        min-height: 100px;
        border-bottom: solid 1px;
        padding-top: 32px;
        padding-bottom: 32px;

        font-size: min(3vw, 48px);

        @include media-breakpoint-down(xl) {
            font-size: max(4.5vw, 20px);
            width: 100%;
        }

        @include media-breakpoint-up(xl) {
            border-right: solid 1px;
            border-bottom: none;
        }

        &__arrow-wrapper {
            fill: $green;
            border-radius: 50%;
            background-color: $black;
            width: 48px;
            height: 48px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 32px;
        }

        &:hover {
            color: $green-900;

            .contact__contact-action-block__arrow-wrapper {
                background-color: $white;
                fill: $green-900;
            }
        }
    }

    &__contact-info-block {
        display: flex;
        flex-direction: column;
        min-height: 50px;
        font-size: max(3.5vw, 24px);
        font-weight: bold;
        height: 7vw;

        margin-top: 32px;
        margin-bottom: 32px;

        @include media-breakpoint-down(sm) {
            &:first-child {
                margin-bottom: unset;
            }
        }

        @include media-breakpoint-up(xl) {
            font-size: min(2.2vw, 40px);
            height: unset;
        }
    }

    &__contact-info-small {
        font-size: max(2vw, 14px);
        @include media-breakpoint-up(lg) {
            font-size: min(3.5vw, 24px);
        }
    }

    ul {
        margin: 0px;
    }

    picture {
        height: 100%;

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-bottom: solid 1px;
        }
    }

    &__divider {
        height: 1px;
        color: $black;
    }

    a {
        text-decoration: none;
        color: inherit;

        &:hover {
            color: $green-900;
        }
    }
}
